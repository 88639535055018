// @flow
import { css } from "styled-components";
import { media } from "@nested/brand";
import { getImage } from "@nested/utils";
import { Helmet } from "react-helmet";

import {
  regularHeading,
  sectionHeading,
} from "../../components/Typography/Headings";
import {
  smallParagraph,
  mediumParagraph,
  largeParagraph,
} from "../../components/Typography/Paragraphs";
import { ResponsiveImage } from "../../components/ResponsiveImage";
import { HeroForm } from "../../components/HeroForm";
import { VideoPlayer } from "./VideoPlayer";

const bigPoppaWrapper = css`
  background-color: ${({ theme }) => theme.palette.hague};
  width: 100%;
  max-width: 1440px;
`;

const wrapper = css`
  width: 100%;
  position: relative;
  max-width: 1180px;
  margin: 0 auto;
`;

const contentWrapper = css`
  ${media.desktop`
    width: 100%;
    display: inline-block;
    vertical-align: top;
  `}
`;

const content = css`
  box-sizing: border-box;
  padding: 30px 20px 0px;
  max-width: 535px;
  margin: 0 auto;
  text-align: center;
  ${media.tablet`
    padding: 30px 20px 10px;
  `}
  ${media.desktop`
    max-width: unset;
    padding: 60px 0 60px 20px;
    width: calc(50% - 20px);
    margin: 0 40px 0 0;
  `}
`;

const headingLabel = css`
  ${sectionHeading}
  color: white;
  text-align: center;
  opacity: 0.8;
  margin: 0;
  ${media.desktop`
    margin-top: 30px;
    text-align: left;
  `}
`;

const mainHeading = css`
  margin: 0;
  text-align: center;

  ${media.desktop`
    margin-top: 20px;
    text-align: left;
  `}
  span {
    display: block;
    ${regularHeading} /* Magical scalable font sizing */ font-size: 8.75vw;
    line-height: 10.75vw;
    margin: 0 calc(6.25vw - 20px) 3.125vw;
    color: white;
    ${media.tablet`
      color: white;
      font-size: 42px;
      line-height: 46px;
    `}
    ${media.desktop`
      margin: 0 auto;
      font-size: 4.6vw;
      line-height: 5.3vw;
      color: white;
      display: block;
    `}
        ${media.maxDesktop`
      font-size: 55px;
      line-height: 62px;
    `}
  }
`;

const subTitleStyles = css`
  text-align: center;
  margin: 10px 50px 0;

  span {
    ${mediumParagraph}
    text-align: left;
    color: white;
    opacity: 0.8;
  }

  ${media.tablet`
    max-width: 460px;
  `}
  ${media.desktop`
    text-align: left;
    margin: 30px 0 0;
    span {
      ${largeParagraph}
      color: white;
    }
  `}
`;

const videoWrapper = css`
  display: none;
  ${media.desktop`
    width: calc(50% - 40px);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    height: 100%;
    max-height: 100vh;
    margin: 0 20px;
    display: flex;
    align-items: center;
  `}
`;

const heroVideo = css`
  padding-top: 100%;
  margin-top: 30px;
  position: relative;
  overflow: hidden;
  ${media.tablet`
    margin-top: 40px;
  `}
  ${media.desktop`
    margin-top: 0;
    width: 100%;
  `}
`;

const trustpilotWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  text-align: right;
  background-color: ${({ theme }) => theme.palette.hague};
  ${media.desktop`
  align-items: flex-start;
    background-color: ${({ theme }) => theme.palette.hague130};
    width: 40%;
    padding: 30px;
    text-align: left;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: -100%;
      height: 100%;
      width: 100%;
      background-color: ${({ theme }) => theme.palette.hague130};
    }
  `}
`;

const trustpilotText = css`
  display: none;
  ${smallParagraph}
  color: white;
  text-align: center;
  margin: 15px 0 0;
  ${media.desktop`
    display: ${({ wrap }) => (wrap ? "inline-block" : "block")};
    text-align: left;
    margin: 15px 0 0;
  `}
`;

const rightmoveText = css`
  ${trustpilotText}
  display: ${({ wrap }) => (wrap ? "inline-block" : "block")};
  margin-top: 10px;
  ${media.desktop`
    margin-top: 5px;
  `}
`;

const trustpilotWidgetStyle = css`
  ${media.desktop`
  .trustpilot-widget iframe {
    left: -16px !important;
  }
  `};
`;

type Props = {
  formType?: "onboarding" | "emailSubscription",
  heading: PrismicRichText,
  subHeading?: string,
  multilineSubTitle: PrismicRichText,
  submitButtonText: string,
  stateOverride?: any,
  videoLink: string,
};

export const HomepageHeroVideo = ({
  formType = "onboarding",
  heading,
  subHeading,
  multilineSubTitle,
  submitButtonText,
  stateOverride,
  videoLink,
}: Props) => {
  return (
    <div css={bigPoppaWrapper}>
      <Helmet>
        <script
          type="text/javascript"
          src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
          async
        ></script>
      </Helmet>
      <div css={wrapper}>
        <div css={contentWrapper}>
          <div css={content}>
            {subHeading && <h2 css={headingLabel}>{subHeading}</h2>}
            <h1 css={mainHeading}>
              {heading.map(({ text }, index) => (
                <span key={index}>{text} </span>
              ))}
            </h1>
            {multilineSubTitle.length > 0 && (
              <h3 css={subTitleStyles}>
                {multilineSubTitle.map(({ text }, index) => (
                  <span key={index}>{text} </span>
                ))}
              </h3>
            )}
            <HeroForm
              formType={formType}
              stateOverride={stateOverride}
              submitButtonText={submitButtonText}
            />
            <div css={videoWrapper}>
              <div css={heroVideo}>
                <VideoPlayer videoLink={videoLink} />
              </div>
            </div>
          </div>
          <div css={trustpilotWrapper}>
            <div css={trustpilotWidgetStyle}>
              <div
                className="trustpilot-widget"
                data-locale="en-GB"
                data-template-id="5419b732fbfb950b10de65e5"
                data-businessunit-id="58d9801a0000ff00059f547c"
                data-style-height="24px"
                data-theme="dark"
              >
                <a
                  href="https://uk.trustpilot.com/review/www.nested.com"
                  target="_blank"
                  rel="noopener"
                >
                  Trustpilot
                </a>
              </div>
            </div>
            <p css={rightmoveText}>
              We list on{" "}
              <span>
                <ResponsiveImage
                  src={getImage("icons/zoopla.svg")}
                  alt="Zoopla"
                  css="width: 53px; height: 14px; vertical-align: text-bottom;"
                />
              </span>
              ,{" "}
              <span>
                <ResponsiveImage
                  src={getImage("icons/rightmove.svg")}
                  alt="rightmove"
                  css="width: 95px; height: 19px; vertical-align: sub;"
                />
              </span>{" "}
              and{" "}
              <span>
                <ResponsiveImage
                  src={getImage("icons/onthemarket_logo.svg")}
                  alt="OnTheMarket"
                  css="width: 75px; height: 19px; vertical-align: sub; "
                />
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
