// @flow

import { useCallback, useRef, useEffect } from "react";
import { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faAngleRight,
  faAngleLeft,
} from "@fortawesome/free-solid-svg-icons";
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from "body-scroll-lock";
import { media } from "@nested/brand";

type Props = {|
  "data-test"?: string,
  open: boolean,
  onClose: () => void,
  children: React$Node,
  className?: string,
  css?: any,
  next?: ?() => void,
  previous?: ?() => void,
  simple?: boolean,
  wideModal?: boolean,
|};

const backgroundStyle = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  padding: ${({ wideModal }) =>
    wideModal ? "22vh 15px" : "80px calc(0.08 * 100vw)"};
  z-index: 100;
  overflow-y: auto;
  padding-bottom: 50px;
  ${({ open }) =>
    open
      ? css`
          transition: background-color 300ms linear 0ms,
            visibility 300ms linear 0ms;
          visibility: visible;
          background-color: rgb(10, 66, 84, 0.8);
          pointer-events: auto;
        `
      : css`
          transition: background-color 300ms ease 300ms,
            visibility 300ms ease 300ms;
          visibility: hidden;
          background-color: rgb(10, 66, 84, 0);
          pointer-events: none;
        `};

  ${media.tablet`
    padding: ${({ wideModal }) =>
      wideModal ? "22vh 15px" : "calc(40px + 8vh) 20px"};
  `}
`;

const modalStyle = css`
  position: relative;
  background-color: white;
  border-radius: 5px;
  width: 100%;
  max-width: 650px;
  padding: 20px;
  box-sizing: border-box;
  overflow: visible;
  margin: 0 auto 50px;

  ${({ open }) =>
    open
      ? css`
          transition: opacity 300ms ease 300ms, transform 300ms ease 300ms;
          transform: translateY(0px);
          opacity: 1;
        `
      : css`
          transition: opacity 300ms ease 0ms, visibility 300ms ease 0ms,
            transform 300ms ease 0ms;
          transform: translateY(3em);
          opacity: 0;
        `}

  ${media.desktop`
    padding: 50px;
  `}
`;

const closeButtonStyle = css`
  position: absolute;
  top: -40px;
  right: 0px;
  border: 0px;
  background-color: ${({ theme }) => theme.palette.hague50};
  border-radius: 17px;
  cursor: pointer;
  color: white;
  font-size: 16px;
  line-height: 24px;
  padding: 4px 30px 4px 12px;
  font-weight: 500;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
`;

const iconStyle = css`
  position: absolute;
  height: 24px !important;
  top: 4px;
  right: 12px;
`;

const arrowStyle = css`
  padding: 0;
  box-sizing: border-box;
  position: absolute;
  z-index: 1;
  border-radius: 50%;
  top: 56px;
  height: 36px;
  width: 36px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 24px;
  ${({ disabled }) =>
    disabled
      ? css`
          border: 1.5px solid ${({ theme }) => theme.palette.hague20};
          color: ${({ theme }) => theme.palette.hague20};
        `
      : css`
          cursor: pointer;
          border: 1.5px solid ${({ theme }) => theme.palette.hague150};
          color: ${({ theme }) => theme.palette.hague150};
        `};
`;

const leftArrowStyle = css`
  ${arrowStyle}
  left: -18px;
  ${media.tablet`
    ${({ simple }) =>
      simple ||
      css`
        left: -56px;
      `}
  `}
`;

const rightArrowStyle = css`
  ${arrowStyle}
  right: -18px;
  ${media.tablet`
    ${({ simple }) =>
      simple ||
      css`
        right: -56px;
      `}
  `}
`;

const ESCAPE_KEY = 27;
const LEFT_ARROW_KEY = 37;
const RIGHT_ARROW_KEY = 39;

export const Modal = ({
  open,
  onClose,
  children,
  simple,
  className,
  next,
  previous,
  wideModal = false,
  "data-test": dataTest,
}: Props) => {
  const modalRef = useRef();
  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      switch (e.keyCode) {
        case ESCAPE_KEY:
          onClose();
          break;
        case LEFT_ARROW_KEY:
          previous && previous();
          break;
        case RIGHT_ARROW_KEY:
          next && next();
          break;
        default:
          break;
      }
    },
    [next, previous, onClose],
  );

  useEffect(() => {
    if (open) {
      document.addEventListener("keydown", handleKeyDown);
    }
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown, open]);

  useEffect(() => {
    if (open) {
      disableBodyScroll(modalRef.current);
    } else {
      enableBodyScroll(modalRef.current);
    }
  }, [open]);

  useEffect(() => {
    // Callback for unmount of the modal to ensure locks aren't left over,
    // for example when clicking a link inside a modal
    return () => {
      clearAllBodyScrollLocks();
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div
      role="presentation"
      css={backgroundStyle}
      open={open}
      onClick={onClose}
      ref={modalRef}
      wideModal={wideModal}
    >
      <div
        role="presentation"
        open={open}
        onClick={(e) => e.stopPropagation()}
        css={modalStyle}
        className={className}
        data-test={dataTest}
      >
        <button
          data-test="close-modal"
          css={closeButtonStyle}
          onClick={onClose}
        >
          Close
          <FontAwesomeIcon css={iconStyle} icon={faTimes} />
        </button>
        {(next || previous) && (
          <>
            <button
              simple={simple}
              disabled={!previous}
              css={leftArrowStyle}
              onClick={previous}
            >
              <FontAwesomeIcon icon={faAngleLeft} />
            </button>
            <button
              simple={simple}
              disabled={!next}
              css={rightArrowStyle}
              onClick={next}
            >
              <FontAwesomeIcon icon={faAngleRight} />
            </button>
          </>
        )}
        {children}
      </div>
    </div>
  );
};
