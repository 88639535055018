// @flow
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faExpandArrowsAlt } from "@fortawesome/free-solid-svg-icons";

const Button = styled.button`
  background-color: ${({ theme }) => theme.palette.hague80};
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  width: 40px;
  height: 40px;
  color: white;
  font-size: 21px;
  padding: 0px;
  text-align: center;
  z-index: 5;
  cursor: pointer;
`;

const ExpandButton = styled(Button)`
  font-size: 21px;
`;

const PlayButton = styled(Button)`
  font-size: 16px;
`;

type Props = {
  onClick: () => void,
  className?: string,
};

export const Expand = ({ onClick, className }: Props) => (
  <ExpandButton onClick={onClick} className={className}>
    <FontAwesomeIcon icon={faExpandArrowsAlt} />
  </ExpandButton>
);

export const Play = ({ onClick, className }: Props) => (
  <PlayButton onClick={onClick} className={className}>
    <FontAwesomeIcon icon={faPlay} />
  </PlayButton>
);
