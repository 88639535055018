import { useRef, useState } from "react";
import { css } from "styled-components";
import { Modal, media } from "@nested/brand";
import { HouseWindowDevice } from "@nested/brand/src/icons";
import { Expand } from "../../../../marketing-site/app/components/VideoControls/VideoControls";

const expandButton = css`
  display: inline-block;
  position: absolute;
  bottom: 20%;
  right: 45%;
`;

const videoLiner = css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const heroVideoStyles = css`
  height: 100%;
  object-fit: cover;
  width: 100%;
`;

const videoWindowOverlay = css`
  height: 100%;
  width: 100%;
  z-index: 1;
  display: block;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const modalStyles = css`
  padding: 0;
  background-color: ${({ theme }) => theme.palette.hague80};
  border-radius: 0;
  max-width: 1024px;
  ${media.tablet`
    height: 80%
    width: auto;
  `}
`;

const videoInModal = css`
  height: 100%;
  object-fit: scale-down;
`;

export const VideoPlayer = ({ videoLink }) => {
  const [showModal, setShowModal] = useState(false);
  const modalVideoRef = useRef();
  const heroVideoRef = useRef();
  const expandVideo = () => {
    heroVideoRef.current.pause();
    setShowModal(true);
    modalVideoRef.current.play();
  };

  const closeVideo = () => {
    modalVideoRef.current.pause();
    setShowModal(false);
    heroVideoRef.current.play();
  };
  return (
    <>
      {/* eslint-disable-next-line */}
      <div onClick={expandVideo} className="chromatic-ignore">
        <div css={videoLiner}>
          <video
            ref={heroVideoRef}
            css={heroVideoStyles}
            autoPlay
            loop
            playsInline
            muted
          >
            <source src={videoLink} type="video/mp4" />
          </video>
          <Expand css={expandButton} onClick={expandVideo} />
          <HouseWindowDevice css={videoWindowOverlay} />
        </div>
      </div>
      <Modal onClose={closeVideo} open={showModal} css={modalStyles}>
        {/* eslint-disable-next-line */}
        <video
          ref={modalVideoRef}
          controls
          autoPlay={showModal}
          css={videoInModal}
          muted={!showModal}
        >
          <source src={videoLink} type="video/mp4" />
        </video>
      </Modal>
    </>
  );
};
