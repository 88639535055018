// @flow
type Props = {
  className?: string,
  fill?: string,
};

export const HouseWindowDevice = ({ className, fill = "#0A4254" }: Props) => {
  return (
    <svg
      viewBox="0 0 655 655"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g stroke={fill} strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          d="M37.446841,655 L0,655 L0,617.538383 C0,638.227863 16.7655218,655 37.446841,655 Z M655,617.538383 L655,655 L617.553159,655 C638.234478,655 655,638.227863 655,617.538383 Z M0,0 L655,0 L655,267.150319 C654.991593,243.776163 644.084976,221.743643 625.506117,207.569556 L350.189908,7.66764809 C336.781949,-2.5558827 318.198068,-2.5558827 304.79011,7.66764809 L29.4938833,207.569556 C10.9150238,221.743643 0.00840686579,243.776163 0,267.150319 L0,0 Z"
          fill={fill}
        ></path>
      </g>
    </svg>
  );
};
